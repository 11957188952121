/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

.sidebar-background-enter {
  @apply opacity-0;
}
.sidebar-background-enter-active {
  @apply opacity-100;
  @apply duration-300;
  @apply ease-in-out;
}
.sidebar-background-exit {
  @apply opacity-100;
}
.sidebar-background-exit-active {
  @apply opacity-0;
  @apply duration-500;
}

.sidebar-enter {
  transform: translateX(-100%);
}
.sidebar-enter-active {
  transform: translateX(0);
  @apply duration-300;
  @apply ease-in-out;
}
.sidebar-exit {
  transform: translateX(0);
}
.sidebar-exit-active {
  transform: translateX(-100%);
  @apply duration-500;
  @apply ease-in-out;
}

@tailwind utilities;
